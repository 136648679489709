import { Component, Vue, Prop } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Report as ReportModel, Media } from '@/models/Report';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import { cloneDeep } from 'lodash';

@Component<AttachmentDialog>({})
export default class AttachmentDialog extends Vue {
  public $pageTitle = 'Bijlagen';

  @Prop()
  protected report!: ReportModel;

  @Prop()
  protected attachment!: Media;

  @Prop()
  protected type!: string;

  protected isLoading = false;

  // Upload / Edit file
  protected isUploadingFile = false;

  protected uploadedFile = '';

  protected acceptedFileTypes: string[] = [
    'image/png',
    'image/jpeg',
    'image/svg+xml',
    'pdf',
  ];

  protected fileTypes: FileType[] = [
    {
      label: 'Offerte',
      value: 'offerte',
    },
    {
      label: 'Addendum',
      value: 'addendum',
    },
    {
      label: 'Nader onderzoek',
      value: 'nader_onderzoek',
    },
  ];

  protected fileDescription = '';

  protected selectedFile: File | null = null;

  protected selectedFileType = 'offerte';

  protected showInReport = '0';

  protected selectedAttachment: Media | null = null;

  protected mounted() {
    if (! this.attachment) {
      return;
    }

    this.selectedAttachment = cloneDeep(this.attachment);
    this.fileDescription = this.selectedAttachment.description ? this.selectedAttachment.description : '';
    this.selectedFileType = this.selectedAttachment.type ? this.selectedAttachment.type : '';
    this.showInReport = this.selectedAttachment.show_in_report ? this.selectedAttachment.show_in_report : '1';
  }

  protected close() {
    this.$emit('input', false);
    this.selectedAttachment = null;
  }

  protected handleItemDropped(file: File) {
    if (! file) {
      return;
    }

    this.selectedFile = file;
    const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
    fileUploadComponent.uploaded();
  }

  protected saveUploadingFile() {
    if (! this.isEditingAttachment) {
      this.create();
      return;
    }

    this.update();
  }

  protected update() {
    this.isLoading = true;
    if (! this.selectedAttachment) {
      return;
    }

    let payload = {
      description: this.fileDescription,
      type: this.selectedFileType,
    };

    let typePayload = {};

    if (this.type === 'attachment') {
      typePayload = {
        show_in_report: this.showInReport,
      };
    }

    payload = { ...payload, ...typePayload };

    this.report
      .updateMediaEndpoint(this.selectedAttachment.uuid ? this.selectedAttachment.uuid : '', payload)
      .then((response: AxiosResponse) => {
        this.$emit('attachmentCreated');
        this.close();
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected create() {
    this.isLoading = true;

    let payload = {
      description: this.fileDescription,
      file: this.selectedFile,
    };

    let typePayload = {};

    if (this.type === 'perspective') {
      typePayload = {
        type: 'picture',
      };
    }

    if (this.type === 'maps') {
      typePayload = {
        type: 'floorplan',
      };
    }

    payload = { ...payload, ...typePayload };

    if (! this.selectedFile) {
      this.isLoading = false;
      return;
    }

    new ReportModel()
      .mediaEndpoint(this.report.uuid ? this.report.uuid : '')
      .create(payload)
      .then((response: AxiosResponse) => {
        this.$emit('attachmentCreated');
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected get isEditingAttachment() {
    return !! this.selectedAttachment;
  }
}

interface FileType {
  label: string;
  value: string;
}
