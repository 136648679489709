var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "defaultDialog" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          {
                            staticClass: "dialog-close",
                            on: { click: _vm.close }
                          },
                          on
                        ),
                        [_vm._v("close")]
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Sluiten")])]
          ),
          _c(
            "v-container",
            { attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "align-end": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                    _c("h2", { staticClass: "dialog-title" }, [
                      _vm.type === "attachment"
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isEditingAttachment
                                    ? "Bijlage aanpassen"
                                    : "Bijlage toevoegen"
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _vm.type === "perspective"
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isEditingAttachment
                                    ? "Aanzichtfoto aanpassen"
                                    : "Aanzichtfoto toevoegen"
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _vm.type === "maps"
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isEditingAttachment
                                    ? "Plattegrond aanpassen"
                                    : "Plattegrond toevoegen"
                                ) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm.isLoading
                    ? [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("LoaderCard", {
                              attrs: { flat: "", type: "spinner--center" }
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  !_vm.isLoading
                    ? [
                        _c(
                          "v-flex",
                          { staticClass: "field", attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: "Beschrijving",
                                "hide-details": true
                              },
                              model: {
                                value: _vm.fileDescription,
                                callback: function($$v) {
                                  _vm.fileDescription = $$v
                                },
                                expression: "fileDescription"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.type === "attachment" &&
                        !_vm.$store.state.isServiceOrganization
                          ? _c(
                              "v-flex",
                              { staticClass: "field", attrs: { xs12: "" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Wilt u deze bijlage in het rapport zichtbaar maken?"
                                  )
                                ]),
                                _c(
                                  "v-radio-group",
                                  {
                                    attrs: { row: "", "hide-details": "" },
                                    model: {
                                      value: _vm.showInReport,
                                      callback: function($$v) {
                                        _vm.showInReport = $$v
                                      },
                                      expression: "showInReport"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-radio",
                                      {
                                        attrs: {
                                          value: "1",
                                          color: "secondary"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label"
                                          },
                                          [_vm._v("Ja")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-radio",
                                      {
                                        attrs: {
                                          value: "0",
                                          color: "secondary"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label"
                                          },
                                          [_vm._v("Nee")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.isEditingAttachment
                          ? _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    staticClass: "fileUpload__container",
                                    attrs: {
                                      "fill-height": "",
                                      "mb-3": "",
                                      "pa-1": "",
                                      wrap: ""
                                    }
                                  },
                                  [
                                    _c("MiFileUpload", {
                                      ref: "fileUpload",
                                      attrs: {
                                        filePath: _vm.uploadedFile,
                                        clearAfterUpload: false,
                                        multiple: false
                                      },
                                      on: {
                                        itemDropped: function($event) {
                                          return _vm.handleItemDropped($event)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "mt-5": "" } },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: { "justify-end": "", "align-center": "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { shrink: "" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "fadedText link mr-3",
                                        on: { click: _vm.close }
                                      },
                                      [_vm._v("Annuleren")]
                                    ),
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color: "primary",
                                          small: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.saveUploadingFile($event)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("actions.save")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }